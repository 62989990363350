import React from "react"
import { Button, TextInput, Form, Select, SelectItem} from "carbon-components-react"
import { ZoomMtg } from '@zoomus/websdk'

function getText() {
if (typeof window !== 'undefined') {
    console.log(document.getElementById("inputName").value)
    console.log(document.getElementById("selectRol").value)
    
    if (!document.getElementById("inputName").value) {
        alert("Debes Introducir un Nombre")
        return false
    }
    if(!document.getElementById("selectRol").value){
        alert("Debes seleccionar un rol")
        return false
    }
    document.getElementById("zmmtg-root").style.display = "block"
    
    console.log("checkSystemRequirements")
    console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()))
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.7.4/lib", "/av")
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareJssdk()
    window.$.i18n.reload("es-ES");

    const API_KEY = "ju5yLujvSk-0eioL2XOAXQ"
    const API_SECRET = "TMPsN33kkHCQUYLCrWdQ4Nbh8DsM8RBHRNY8"

    const meetConfig = {
        apiKey: API_KEY,
        apiSecret: API_SECRET,
        meetingNumber: "4964677551",
        userName: document.getElementById("inputName").value,
        passWord: "032774",
        leaveUrl: "https://webinarsmartbits.es/",
        role: parseInt(document.getElementById("selectRol").value, 10), //1 Host 0 participante role: parseInt(document.getElementById("selectRol").value, 10)
    }
    ZoomMtg.generateSignature({
        meetingNumber: meetConfig.meetingNumber,
        apiKey: meetConfig.apiKey,
        apiSecret: meetConfig.apiSecret,
        role: meetConfig.role,
        success(res) {
        console.log("signature", res.result)
        ZoomMtg.init({
            leaveUrl: "https://webinarsmartbits.es/",
            isSupportAV: true,
            success() {
            ZoomMtg.join({
                meetingNumber: meetConfig.meetingNumber,
                userName: meetConfig.userName,
                signature: res.result,
                apiKey: meetConfig.apiKey,
                passWord: meetConfig.passWord,
                success() {
                console.log("join meeting success")
                },
                error(res) {
                console.log(res)
                },
            })
            },
            error(res) {
            console.log(res)
            },
        })
        },
    })
    
}
}
const webinarCall = () => (
  <>
    <Form name="formulario">
      <TextInput
        helperText="Con este nombre te verán los demás"
        id="inputName"
        name="nombre"
        invalidText="A valid value is required"
        labelText="Necesitamos tu nombre"
        placeholder="Nombre"
      />
      <br></br>
      <Select
        defaultValue=""
        helperText="Este campo es obligatorio"
        id="selectRol"
        invalidText="A valid value is required"
        labelText="Selecciona un Rol"
      >
          <SelectItem text="Selecciona una opción" value="" />
          <SelectItem text="Asistente" value="0" />
          <SelectItem text="Host" value="1" />
          <SelectItem text="Moderador" value="5" />
      </Select>
      <br></br>
    </Form>
    <Button type="submit" onClick={getText}>
      Entrar en el Webinar
    </Button>
    <br></br>
  </>
)

export default webinarCall